'use client';

export default function Error({ error, reset }) {
  return (
    <div className="text-center border border-solid p-5 h-full mx-20 my-3 rounded-xl">
      <div className="rounded-xl w-1/2 mx-auto p-5">
        <h1 className="text-2xl font-bold mb-2 text-red-600">Something went Wrong!</h1>
        <p className="text-red-600 my-1"><span className="font-bold">Message :  </span>{error.message}</p>
        <button className="border border-solid p-2 border-black my-2 rounded-xl hover:bg-slate-400 hover:text-white" onClick={() => reset()}>Try again</button>
      </div>
    </div>
  )
}
